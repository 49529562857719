function logsController(DatatableLang, $state, $scope, WS, $window ,$translate) {
    'ngInject';
    const vm = this;
     vm.filter = {};
     vm.filter.date_debut = '';
    vm.filter.date_fin = '';;
    vm.getLogs = function() {
        WS.getData('logs/getlogs', vm.filter)
            .then(function(response) {
                if (response.status == 200) {
                    vm.logs = response.data;
                    $scope.$apply();
                    // $('.table').DataTable({
                    //     "language": DatatableLang
                    // });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };



    vm.exportgetLogs = function() {
        var filename = "Logs -" +   vm.filter.date_debut + '-' 
                                                     + vm.filter.date_fin;

            var file = {
                sheetid: filename,
                headers: true,
                caption: {
                    title: filename,
                    style: 'font-size: 50px; color:blue;'
                },
                column: {
                    style: 'font-size:20px;background:#d1d1d1;'
                },
                columns: [
                    
                    { columnid: 'user', title: 'User' },
                    { columnid: 'query', title: 'requete' },
                    { columnid: 'params', title: 'Parametre' },
                    { columnid: 'created_at', title: 'Date' },
                ],
                row: {
                    style: function(sheet, row, rowidx) {
                        return 'text-align:center;background:' + (rowidx % 2 ? '#fff' : '#f1f1f1') + ';height:100px!important;';
                    }
                }
            };
            alasql('SELECT * INTO XLS("' + filename + '.xls",?) FROM ?', [file, vm.kilometrages]);
    };


}
export default {
    name: 'logsController',
    fn: logsController
};