function fideliteController(DatatableLang, $state, $scope, WS, $window) {
    'ngInject';
    const vm = this;
    vm.fidelites = {};

    /********************************fidelitesss*****************************************/
    vm.getFidelites = function() {
        WS.get('fidelites/all')
            .then(function(response) {

                if (response.status == 200) {
                    vm.fidelites = response.data;
                    $scope.$apply();
                    $('.table_fidelites').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getFidelitesLite = function() {
        WS.get('fidelites/lite')
            .then(function(response) {

                if (response.status == 200) {
                    vm.fideliteslite = response.data;

                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    };
    vm.getTypes = function () {
        WS.get('type_clients', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.types = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectRoutes = function () {
        WS.get('routing', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.routings = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.selectProduit = function() {
        WS.get('produits/selectProduit').then(
            function(response) {
                vm.selectproduit = response.data;

                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    }

    vm.getconditionFidelite = function() {
        var url = 'fidelites/getconditionfidelites/' + $state.params.code;
        WS.get(url).then(
            function(response) {
                vm.conditionsfidelites = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.getconditionFideliteByCode = function(code) {
        var url = 'fidelites/getconditionfidelites/' + code;
        WS.get(url).then(
            function(response) {
                vm.conditionsfidelitesbycode = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };

    vm.gettotalpointparclients = function() {
        var url = 'fidelites/total';
        WS.get(url).then(
            function(response) {
                vm.total = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            });
    };
    vm.StoreConditionFidelite = function(fidelite) {

        fidelite.fidelite_code = $state.params.code;
        console.log(fidelite);
        WS.post('fidelites/conditions', fidelite).then(
            function(response) {
                swal(
                    'Succès',
                    'Condition ajouté avec Succès',
                    'success'
                ).then(function() {
                    $window.location.reload();
                });
            },
            function(error) {
                console.log(error);
            });
    };

    vm.UpdateConditionfidelite = function(fidelite) {
        WS.put('fidelites/conditions/' + fidelite.id, fidelite).then(
            function(response) {
                swal(
                    'Succès',
                    'Condition modifié avec Succès',
                    'success'
                ).then(function() {
                    $window.location.reload();
                });
            },
            function(error) {
                console.log(error);
            });
    };
    vm.deleteConditionfidelite = function(id) {
        swal({
            title: 'Condition',
            text: "voulez vous vraiment supprimer cette Condition!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('fidelites/conditions/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Condition supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function(error) {
                    console.log(error);
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette Condition!!',
                        'warning'
                    );
                }
            );
        });
    };

    vm.ajouterFidelite = function(fidelite) {
        WS.post('fidelites', fidelite)
            .then(function(response) {
                if (response.data.status == true) {
                    swal(
                        'Succès',
                        'fidelites a ete ajoutée avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    );
                }
            })
            .then(null, function(error) {
                console.log(error);
            });
    };

    vm.updateFidelite = function(fidelite) {
        console.log(fidelite);
        WS.put('fidelites/' + fidelite.id, fidelite).then(
            function(response) {
                swal(
                    'Succès',
                    'Fidelite modifié avec Succès',
                    'success'
                ).then(function() {
                    $window.location.reload();
                });
            },
            function(error) {
                console.log(error);
            });
    };

    vm.deleteFidelite = function(id) {
        swal({
            title: 'Condition',
            text: "voulez vous vraiment supprimer cette Fidelite!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function() {
            WS.delete('fidelites/' + id).then(
                function() {
                    swal(
                        'Succès',
                        'Fidelite supprimée avec Succès.',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                },
                function() {
                    swal(
                        'Attention!',
                        'vous ne pouvez pas supprimer cette Fidelite!!',
                        'warning'
                    );
                }
            );
        });
    };

    vm.findAffectationClientFidelite = function() {
        WS.get('fidelites/clients').then(
            function(response) {
                if (response.status == 200) {
                    vm.affectationclientfidelites = response.data;

                    $scope.$apply();
                    $('.table_aff_gratuite').DataTable({
                        "language": DatatableLang
                    });
                } else {}
            },
            function(error) {
                console.log(error);
            }
        );
    };


    vm.addAffectationClientFidelite = function(affectation) {
        WS.post('fidelites/addaffectationclient', affectation).then(
            function(response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Affectation Client ajouté avec Succès',
                        'success'
                    ).then(function() {
                        $window.location.reload();
                    });
                } else {
                    swal(
                        'Warning',
                        response.data.message,
                        'warning'
                    )
                }
            },
            function() {
                console.log(error);
            }
        );
    };

    vm.getCategoriesCommerciale = function() {
        WS.get('categoriescommerciale').then(
            function(response) {
                vm.categoriescommerciale = response.data;
                $scope.$apply();
            },
            function(error) {
                console.log(error);
            }
        );
    };


    vm.selectRoutes = function () {
        WS.get('routing/selectRouteWithDepotUser')
            .then(function (response) {
                vm.routings = response.data;
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getActivites = function () {
        WS.get('activites', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.activites = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getZones = function() {
        WS.get('zones', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }
    vm.getRegions = function() {
        WS.get('regions', vm.access)
            .then(function(response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                } else {}
            })
            .then(null, function(error) {
                console.log(error);
            });
    }



}
export default {
    name: 'fideliteController',
    fn: fideliteController
};