function userController($translate, $state, $scope, WS, $window, AppSettings, NgMap, $timeout, Flash, $http, $compile, DatatableLang) {
    'ngInject';
    const vm = this;

    vm.users = {};
    vm.user = {};
    vm.depot = {};
    vm.societes = {};
    vm.gammes = {};
    vm.produits = {};
    vm.detail = {};
    vm.files = [];
    vm.position = {};
    vm.keymaps = AppSettings.keymaps;
    vm.search = {};
    vm.pwd = {};

    vm.mapsForUser = function (idmaps, latitude, longitude) {
        $timeout(function () {
            NgMap.getMap({ id: idmaps }).then(function (response) {
                response.setCenter({ lat: Number(latitude), lng: Number(longitude) });
                google.maps.event.trigger(response, 'resize');
                response.setCenter({ lat: Number(latitude), lng: Number(longitude) });
            });
        }, 1000);
    };

    vm.getSocietes = function () {
        WS.get('societes/selectedSocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.societes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getSousSocietes = function () {
        WS.get('soussocietes/selectSoussocietes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.soussocietes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRoles = function () {
        WS.get('roles')
            .then(function (response) {
                if (response.status == 200) {
                    vm.roles = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getRegions = function () {
        WS.get('regions')
            .then(function (response) {
                if (response.status == 200) {
                    vm.regions = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getZones = function () {
        WS.get('zones')
            .then(function (response) {
                if (response.status == 200) {
                    vm.zones = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSecteurs = function () {
        WS.get('secteurs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.secteurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getAffectations = function () {
        WS.get('affectations_achats')
            .then(function (response) {
                if (response.status == 200) {
                    vm.affectationsachats = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.deleteAffectations = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette Affectation!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('affectations_achats/' + id).then(
                function () {
                    swal(
                        'Succès',
                        'Affectation Supprimée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette Affectation',
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }
    vm.updateAffectation = function (affectation) {
        WS.put('affectations_achats/' + affectation.id, affectation)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'affectation modifiée avec Succès',
                        'success'
                    )
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.ajouterAffectation = function (affectation) {
        WS.post('affectations_achats', affectation)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'affectation ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error', response.data.message, 'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getCommercialByEquipement = function (code, liste, attribute) {
        if (!liste) return;
        for (var i = 0; i < liste.length; i++) {

            if (liste[i][attribute] == code) {
                return liste[i];
            }

        }
    }
    vm.delete = function (id) {
        swal({
            title: 'Supprimer',
            text: "Voulez vous vraiment supprimer cette utilisateur!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('users/' + id).then(
                function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            response.data.message,
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else {
                        swal(
                            'Attention',
                            response.data.message,
                            'warning'
                        );
                    }

                },
                function () {
                    swal(
                        'Attention!',
                        'Vous ne pouvez pas supprimer cette utilisateur',
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }

    vm.getGammes = function () {
        WS.getScache('gammes')
            .then(function (response) {
                if (response.status == 200) {
                    vm.gammes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getGammes();

    vm.getcommercial = function () {
        WS.get('users/query/grade="LIV"', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.commercials = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getSousFamilles = function () {
        WS.get('sousfamilles')
            .then(function (response) {
                if (response.status == 200) {
                    vm.sousfamilles = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.getDetails = function () {
        WS.getScache('users/' + $state.params.id)
            .then(function (response) {
                if (response.status == 200) {
                    vm.detail = response.data;
                    vm.detail.isactif = vm.detail.isactif.toString();
                    vm.detail.pre_commande = vm.detail.pre_commande.toString();
                    vm.detail.vente_comptoir = vm.detail.vente_comptoir.toString();
                    vm.detail.only_my_data = vm.detail.only_my_data.toString();
                    $scope.$apply();
                }
                console.log(vm.detail);
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.refreshuser = function () {
        vm.users = {};
        vm.chosedUser = {};
        WS.get('users')
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getUsers = function () {
        vm.users = {};
        vm.chosedUser = {};
        WS.get('users')
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data;
                    $scope.$apply();
                    $('.table_users').DataTable({
                        "language": DatatableLang
                    });
                    // $('.table').DataTable({
                    //     "language": DatatableLang,
                    //     "processing": true,
                    //     "serverSide": true,
                    //     "ajax": "http://localhost/bissdist/public/api/v1/produits"
                    // });
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    /*var add = function() {
        vm.users.push({
            "id": 1,
            "nom": "Mohamed",
            "prenom": "Ismael",
            "grade": "LIV",
            "matricule": "6030000005044",
            "adresse": "Tunis",
            "login": "mohamed@gmail.com",
            "email": "mohamed@gmail.com",
            "photo": null,
            "telephone": "71 222 222",
            "mobile": "22 222 222",
            "isactif": "1",
            "isadmin": "0",
            "equipe": "1",
            "gamme": "",
            "societe_code": null,
            "secteur_code": null,
            "code": "USER1",
            "code_a_barre": "6030000005044",
            "created_at": "2017-03-13 09:12:19",
            "updated_at": "2017-03-17 13:42:35",
            "trace": null,
            "affectations": []
        });
        $scope.$apply();



        setTimeout(function() {
            console.log('add');
            add();
        }, 5000);
    }*/


    vm.getProduits = function () {
        WS.get('produits/all')
            .then(function (response) {
                if (response.status == 200) {
                    vm.produits = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.store = function () {
        if (vm.stopAddUser == true) {
            swal(
                'Attention',
                'vous avez atteint le nombre maximal de licences',
                'warning'
            )
        } else {
            vm.detail.photo = vm.files[0];
            var route;
            var method;
            var msg;
            if ($state.params.id) {
                route = 'users/' + $state.params.id;
                method = 'PUT';
                msg = 'modifiée';
            } else {
                route = "users";
                method = "POST";
                msg = 'ajoutée';
            }

            WS.postwithimg(route, vm.detail, method)
                .then(function (response) {
                    if (response.data.success == true) {
                        swal(
                            'Succès',
                            'User ' + msg + ' avec Succès',
                            'success'
                        ).then(function () {
                            $window.location.reload();
                        });
                    } else if (response.data.success == false) {
                        swal('Error', response.data.message, 'Error');
                    }
                }, function (error) {
                    console.log(error);
                });
        }

    };

    $scope.uploadedFile = function (element) {
        $scope.currentFile = element.files[0];
        var reader = new FileReader();
        reader.onload = function (event) {
            vm.detail.photo = event.target.result
            $scope.$apply(function () {
                vm.files = element.files;
            });
        }
        reader.readAsDataURL(element.files[0]);
    }

    vm.save = function (detail) {
        console.log(vm.detail);
        WS.post('users', vm.detail)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Utilisateur ajoutée avec Succès',
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }
    vm.update = function (detail) {

        WS.put('users/' + $state.params.id, vm.detail)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Utilisateur modifié avec Succès',
                        'success'
                    ).then(function () {
                        vm.getUsers();
                        $window.location.reload();

                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }


    vm.updateEtat = function (id, type) {


        WS.get('users/connected/' + id + '/' + type)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Utilisateur modifié avec Succès',
                        'success'
                    ).then(function () {

                        $window.location.reload();

                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.updateEquipement = function (code) {
        console.log(code);
        WS.get('equipements/updateEquipementLastSync/' + code)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Equipement modifié avec Succès',
                        'success'
                    ).then(function () {

                        $window.location.reload();

                    })
                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.refresh = function () {
        $state.reload();
        $('#myModal').modal('hide');
    }
    vm.addGammeToUser = function (gamme_code, user_code, $event) {
        WS.post('users/' + user_code + '/affectation_gamme/' + gamme_code, {})
            .then(function (response) {
                if (response.status == 200) {
                    console.log($event);
                    $($event.currentTarget).addClass('btn-success');
                    $scope.$apply();
                    $window.location.reload();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.removeGammeFromUser = function (gamme_code, user_code, $event) {
        WS.delete('users/' + user_code + '/affectation_gamme/' + gamme_code, null)
            .then(function (response) {
                if (response.status == 200) {
                    console.log($event);
                    $($event.currentTarget).removeClass('btn-success');
                    $scope.$apply();
                    $window.location.reload();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.affectedGamme = function (gamme_code) {
        var r = $.grep(vm.choosedUser.affectations, function (item) {
            return item.gamme_code == gamme_code;
        });
        return r.length > 0;
    }
    vm.addChargment = function () {
        vm.chargement.fromAdmin = 1;
        WS.post('mouvements', vm.chargement)
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        'Succès',
                        'Chargement envoyer avec Succès',
                        'success'
                    )
                        .then(function () {
                            $window.location.reload();
                        })

                }
            })
            .then(null, function (error) {
                console.log(error);
                swal(
                    'ERROR !',
                    error.data.message,
                    'error'
                )

            });
    }

    vm.getPays = function () {
        WS.getScache('pays').then(function (response) {
            vm.getpays = response.data;
            $scope.$apply();
        }, function (error) {
            console.log(error);
        });
    };

    vm.updatePays = function (pays) {
        WS.put('pays/' + pays.id, pays)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        'Succès',
                        'Modification avec Succès',
                        'success'
                    )
                } else {
                    swal(
                        'warning',
                        'modification failed',
                        'warning'
                    )
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.changePassword = function () {
        vm.pwd.user_id = vm.detail.id;

        if (vm.pwd.password === vm.pwd.c_password) {
            WS.post('users/changePassword', vm.pwd).then(function (response) {
                console.log(response);
                Flash.create('success', response.data.message);
                $scope.$apply();
            }, function (error) {
                console.log(error);
                Flash.create('danger', error.data.message);
                $scope.$apply();
            });
        } else {
            console.log("les deux mots de passe ne sont pas identique!!");
        }

    }

    vm.checkcontrat = function () {
        vm.stopAddUser = false;
        //console.log('stopAddUser0', vm.stopAddUser);
        WS.getScache('societes')
            .then(function (response) {
                vm.societes = response.data;
                var contrat_code = vm.societes.contrat;

                $http({
                    method: 'GET',
                    url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                    cache: false
                }).then(function successCallback(response) {
                    if ($.isEmptyObject(response.data) == true) {
                        vm.checkcontrat = false;
                    } else {
                        vm.nbr_license = response.data.nbrlicense;
                        console.log(vm.nbr_license);
                        WS.getScache('users/countLiv').then(function (response) {
                            vm.countliv = response.data;


                            if (Number(vm.countliv) >= Number(vm.nbr_license)) {
                                vm.stopAddUser = true;
                                //console.log('stopAddUser1', vm.stopAddUser);
                            } else {
                                vm.stopAddUser = false;
                                //console.log('stopAddUser2', vm.stopAddUser);
                            }
                            $scope.$apply();
                        }, function (error) {
                            console.log(error);
                        });
                    }
                }, function errorCallback(response) {
                    vm.expiration = true;
                    console.log('error get contrat');
                });
                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.get = function () {
        WS.get('affectation_commercial_depot/list', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.affectations = response.data;
                    $scope.$apply();
                    $('.table_affectations').DataTable({
                        "language": DatatableLang
                    });
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.getdepots = function () {
        WS.get('depots/all', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.depots = response.data;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.selectUsers = function () {
        WS.get('users/selectUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectusers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.selectAllUsers = function () {
        WS.get('users/selectAllUsers', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.selectallusers = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.selectadminForAffectation = function () {
        WS.get('users/listadminforaffectation', vm.access)
            .then(function (response) {
                if (response.status == 200) {
                    vm.listadminforaffectation = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.ajouterAffectation = function (aff) {
        WS.post('affectation_commercial_depot', aff)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Affectation ajoutée avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.updateAffectations = function (type) {
        WS.put('affectation_commercial_depot/' + type.id, type)
            .then(function (response) {
                if (response.data.success == true) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Affectation modifié avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteAffectation = function (id) {
        swal({
            title: $translate.instant('Supprimer'),
            text: $translate.instant("Voulez vous vraiment supprimer cet Affectation!"),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        }).then(function () {
            WS.delete('affectation_commercial_depot/' + id).then(
                function () {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Affectation Supprimé avec Succès'),
                        'success'
                    ).then(function () {
                        $window.location.reload();
                    });
                },
                function () {
                    swal(
                        $translate.instant('Attention!'),
                        $translate.instant('Vous ne pouvez pas supprimer cet Affectation'),
                        'warning'
                    );
                }
            );
        }, function (error) {
            console.log(error);
        });
    }


    vm.logoutOtherUser = (user_code) => {
        WS.post('users/logoutOtherUser', { user_code })
            .then(function (response) {
                if (response.data.success == true) {
                    // swal(
                    //     $translate.instant('Succès'),
                    //     $translate.instant('action avec succée'),
                    //     'success'
                    // ).then(function() {
                    //     $window.location.reload();
                    // });

                } else if (response.data.success == false) {
                    swal('Error',
                        $translate.instant(response.data.message),
                        'error');
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };



    /**
     * affectation depots
     */
    vm.models = {
        selected: null,
        lists: { "depot": [], "affected": [] }
    };
    vm.initializing = true;

    vm.AffectationDepotsToSupervisor = function () {
        WS.getData('users/AffectationDepotsToSupervisor', { user_id: $state.params.id })
            .then(function (response) {
                vm.models.lists.affected = response.data.affected_depots;
                vm.models.lists.depot = response.data.not_affected_depots;

                $scope.$watch('vm.models.lists.affected', function (newModel, oldModel) {
                    if (vm.initializing) {
                        $timeout(function () { vm.initializing = false; });
                    } else {
                        vm.action = {};
                        if (newModel.length > oldModel.length) {
                            _.each(newModel, element => {
                                var exist = _.filter(oldModel, function (value) { return value.depot_code === element.depot_code });
                                if (exist.length == 0) {
                                    vm.action.depot = element;
                                    vm.action.method = 'ADD';
                                    vm.action.user_code = vm.detail.code;

                                }
                            });
                        } else {
                            _.each(oldModel, element => {
                                var exist = _.filter(newModel, function (value) { return value.depot_code === element.depot_code });
                                if (exist.length == 0) {
                                    vm.action.depot = element;
                                    vm.action.method = 'DELETE';
                                    vm.action.user_code = vm.detail.code;
                                }
                            });
                        }
                        if (vm.action) {
                            //console.log(vm.action);
                            WS.post('users/StoreAffectationDepot', vm.action)
                                .then(function (response) {
                                    if (response.data.success == true) {

                                    } else if (response.data.success == false) {
                                        swal('Error', 'en cours de developpement', 'warning');
                                    }
                                })
                                .then(null, function (error) {
                                    console.log(error);
                                });
                        }
                    }
                }, true);




                $scope.$apply();
            })
            .then(null, function (error) {
                console.log(error);
            });
    };




    vm.types_users = ['Pré vendeur', "Cashvan", "Livreur", "Cash & Liv"];
    vm.actifs = ["Inactif", "Actif"];

    vm.getListOfUsers = function () {
        /**
         * focus for detail modal
         */
        $('.dev_nav_center>a.active').focus();
        WS.getData('users/userLists', vm.search)
            .then(function (response) {
                if (response.status == 200) {
                    vm.users = response.data.users;
                    vm.total = response.data.total;
                    $scope.$apply();
                    vm.countDepotByType();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.getListOfRoles = () => {
        WS.getData('roles/roleLists')
            .then(function (response) {
                if (response.status == 200) {
                    vm.roles = response.data;
                    vm.total_actif = _.reduce(vm.roles, function (memo, num) { return memo + num.nbr_actif; }, 0);
                    vm.total_inactif = _.reduce(vm.roles, function (memo, num) { return memo + num.nbr_inactif; }, 0);
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.countDepotByType = () => {
        WS.getData('depots/countDepotByType', { role_code: vm.search.role_code })
            .then(function (response) {
                if (response.status == 200) {
                    vm.types_depots = response.data;
                    vm.count_types_depots = Object.keys(vm.types_depots).length;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.changeBtnColor = (role_code) => {
        $('.btn_role').removeClass('btn_menu_primary');
        $('#' + role_code).addClass('btn_menu_primary');
        $('.btn_livraison').removeClass('btn_menu_primary');
    };

    vm.changeBtnColorDepot = (role_depot) => {
        $('.btn_livraison').removeClass('btn_menu_primary');
        $('#' + role_depot).addClass('btn_menu_primary');
    }

    vm.getSelectedUserByCode = (user_code) => {
        WS.getData('users/getSelectedUserByCode/' + user_code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.user = response.data;
                    vm.user.type = (vm.user.type == null) ? "" : vm.user.type;
                    //console.log(vm.user);

                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.getSelectedDepotByCode = (depot_code) => {
        WS.getData('users/getSelectedDepotByCode/' + depot_code)
            .then(function (response) {
                if (response.status == 200) {
                    vm.depot = response.data;
                    vm.depot.obligation_gps = vm.depot.obligation_gps.toString();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.updateInfoUserByCode = () => {
        WS.post('users/updateInfoUserByCode', { user: vm.user })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                    vm.getListOfUsers();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            })
    };

    vm.updateDetailsUserByCode = () => {
        WS.post('users/updateDetailsUserByCode', { user: vm.user })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                    vm.getListOfUsers();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            })
    };

    vm.updateParamUserByCode = () => {
        WS.post('users/updateParamUserByCode', { user: vm.user })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                    vm.getListOfUsers();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            })
    };



    vm.updateInfoDepotByCode = () => {
        WS.post('depots/updateInfoDepotByCode', { depot: vm.depot })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                    vm.getListOfUsers();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            })
    };

    vm.updateParamDepotByCode = () => {

        WS.post('depots/updateParamDepotByCode', { depot: vm.depot })
            .then(function (response) {
                if (response.status == 200) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                    vm.getListOfUsers();
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            })
    };


    vm.listGammeAffectedToUser = function (user_code) {
        WS.getData('users/listGammeAffectedToUser', { user_code })
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_of_gammes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.listCommercialsAffectedByUser = function (user_code) {
        WS.getData('users/listCommercialsAffectedByUser', { user_code })
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_of_commercials = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.manageAffectationGamme = function (user_code, gamme_code) {
        WS.post('users/manageAffectationGamme', { user_code, gamme_code })
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur d\'insertion!'),
                        'warning'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.manageAffectationCommercial = function (user_code, depot_code) {
        WS.post('users/manageAffectationCommercial', { user_code, depot_code })
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur d\'insertion!'),
                        'warning'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.listSsocieteAffectedToUser = function (user_code) {
        WS.getData('users/listSsocieteAffectedToUser', { user_code })
            .then(function (response) {
                if (response.status == 200) {
                    vm.list_of_soussocietes = response.data;
                    $scope.$apply();
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.manageSsocieteAffectedToUser = function (user_code, soussociete_code) {
        WS.post('users/manageSsocieteAffectedToUser', { user_code, soussociete_code })
            .then(function (response) {
                if (response.data.success) {
                    swal(
                        $translate.instant('Succès'),
                        $translate.instant('Modification terminée avec succès'),
                        'success'
                    );
                } else {
                    swal(
                        $translate.instant('Attention'),
                        $translate.instant('erreur d\'insertion!'),
                        'warning'
                    );
                }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };


    vm.testercheckbox = () => {
        console.log(vm.user.pre_commande);
    };



    /**
     * initialisation des dates
     */
    $(document).ready(function () {

        $(".input_delivre_le").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.user.delivre_le = date;
                $scope.$apply();
            }
        });

        $(".input_date_embauche").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.user.date_embauche = date;
                $scope.$apply();
            }
        });

        $(".input_date_delivrance").datepicker({
            autoClose: true,
            onSelect: function (date) {
                vm.user.date_delivrance = date;
                $scope.$apply();
            }
        });
    });


    vm.showMaps = function (user_code, depot_code) {

        WS.getData('users/infoMaps', { user_code, depot_code })
            .then(function (response) {

                vm.maps_depot = response.data.depot;
                vm.maps_user = response.data.user;
                vm.maps_pays = response.data.pays;

                vm.nbr_client_total = response.data.nbr_client_total.total;


                vm.list_client_hors_tourne = response.data.client_hors_tourne;
                vm.list_client_tourne = response.data.client_tourne;

                vm.client_hors_tourne = response.data.client_hors_tourne.length;
                vm.client_tourne = response.data.client_tourne.length;
                //vm.nbr_client_tourne_jour = response.data.nbr_client_tourne_jour; //array response

                var center_latitude = (vm.maps_user.latitude) ? vm.maps_user.latitude : vm.maps_pays.latitude;
                var center_longitude = (vm.maps_user.longitude) ? vm.maps_user.longitude : vm.maps_pays.longitude;
                $('#container_maps').empty();
                $('#container_maps').append('<div style="width: 100%; height: 550px" id="mapContainerCmds"></div>');

                var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                var osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                var osm = L.tileLayer(osmUrl, {
                    maxZoom: 18,
                    attribution: osmAttrib
                });
                //console.log('from maps : ', vm.getpays)
                var map = new L.Map('mapContainerCmds', {
                    center: new L.LatLng(center_latitude, center_longitude),
                    zoom: 11,
                    fullscreenControl: true,
                    fullscreenControlOptions: {
                        position: 'topright'
                    }
                });

                osm.addTo(map);

                /**
                 * add position user to map
                 */
                if (vm.maps_user.latitude && vm.maps_user.latitude) {
                    var MarkerUserIcon = L.ExtraMarkers.icon({
                        icon: 'fa-user',
                        markerColor: 'blue',
                        shape: 'circle', //'circle', 'square', 'star', or 'penta'
                        prefix: 'fa'
                    });
                    var MarkerUser = L.marker([vm.maps_user.latitude, vm.maps_user.longitude], { icon: MarkerUserIcon }).addTo(map);

                    var infoUserMarker = $compile(`
                                                     <div id="bodyContent" style="width=400px;">
                                                          <h3 class="text-center"> ${$translate.instant('Commercial')}</h3>
                                                          <div><b>Code</b> : ${vm.user.code}</div>
                                                          <div><b>Comercial</b> : ${vm.user.nom} ${vm.user.prenom}</div>
                                                          <div><b>Dépot</b> : ${vm.depot.adresse}  ${vm.depot.libelle}</div>
                                                    </div>
                                                    `)($scope);
                    var popupInfoUser = L.popup({
                        maxWidth: 600,
                        minWidth: 300
                    }).setContent(infoUserMarker[0])
                    MarkerUser.bindPopup(popupInfoUser);
                }

                if (vm.client_hors_tourne > 0) {

                    _.each(vm.list_client_hors_tourne, (element, index) => {
                        var MarkerClHIcon = L.ExtraMarkers.icon({
                            icon: 'fa-home',
                            markerColor: 'red',
                            shape: 'circle', //'circle', 'square', 'star', or 'penta'
                            prefix: 'fa'
                        });
                        if (element.latitude && element.longitude) {
                            var MarkerUser = L.marker([element.latitude, element.longitude], { icon: MarkerClHIcon }).addTo(map);

                            var infoUserMarker = $compile(`
                                                         <div id="bodyContent" style="width=400px;">
                                                              <h3 class="text-center"> ${$translate.instant('Commercial')}</h3>
                                                              <div><b> ${$translate.instant('Code')}</b> : ${element.code}</div>
                                                              <div><b> ${$translate.instant('Client')}</b> : ${element.client} ${element.magasin}</div>
                                                              <div><b> ${$translate.instant('Crédit')}</b> : ${element.encours}</div>
                                                              <div><b> ${$translate.instant('Dernier visite')}</b> : ${element.last_visite}</div>
                                                        </div>
                                                        `)($scope);
                            var popupInfoUser = L.popup({
                                maxWidth: 600,
                                minWidth: 300
                            }).setContent(infoUserMarker[0])
                            MarkerUser.bindPopup(popupInfoUser);
                        }
                    });
                }

                if (vm.client_tourne > 0) {

                    _.each(vm.list_client_tourne, (element, index) => {
                        var MarkerClHIcon = L.ExtraMarkers.icon({
                            icon: 'fa-home',
                            markerColor: 'green',
                            shape: 'square', //'circle', 'square', 'star', or 'penta'
                            prefix: 'fa'
                        });
                        if (element.latitude && element.longitude) {
                            var MarkerUser = L.marker([element.latitude, element.longitude], { icon: MarkerClHIcon }).addTo(map);

                            var infoUserMarker = $compile(`
                                                     <div id="bodyContent" style="width=400px;">
                                                          <h3 class="text-center"> ${$translate.instant('Commercial')}</h3>
                                                          <div><b> ${$translate.instant('Code')}</b> : ${element.code}</div>
                                                          <div><b> ${$translate.instant('Client')}</b> : ${element.client} ${element.magasin}</div>
                                                          <div><b> ${$translate.instant('Crédit')}</b> : ${element.encours}</div>
                                                          <div><b> ${$translate.instant('Dernier visite')}</b> : ${element.last_visite}</div>
                                                          <div><b> ${$translate.instant('Fréquence')}</b> : ${element.frequence}</div>
                                                          <div><b> ${$translate.instant('Jour')}</b> : ${element.code_jour}</div>
                                                    </div>
                                                    `)($scope);
                            var popupInfoUser = L.popup({
                                maxWidth: 600,
                                minWidth: 300
                            }).setContent(infoUserMarker[0])
                            MarkerUser.bindPopup(popupInfoUser);
                        }

                    });
                }




                /**
                 * add polygon and panel of draw
                 */
                var geojsonMarkerOptions = {
                    radius: vm.maps_depot.raduis
                };
                if (vm.maps_depot.layer) {
                    if (vm.maps_depot.raduis) {
                        var paramsdrawitem = {
                            pointToLayer: function (feature, latlng) {
                                return L.circle(latlng, geojsonMarkerOptions);
                            }
                        }
                    } else {
                        var paramsdrawitem = {};
                    }
                }
                var drawnItems = L.geoJson(JSON.parse(vm.maps_depot.layer), paramsdrawitem).addTo(map);

                map.addControl(new L.Control.Draw({
                    edit: {
                        featureGroup: drawnItems
                    },
                    draw: {
                        polyline: true,
                        polygon: {
                            allowIntersection: false, // Restricts shapes to simple polygons
                            showArea: true,
                        },
                        rectangle: true,
                        marker: true,
                        circlemarker: false,
                        circle: false,
                    }
                }));

                map.on(L.Draw.Event.CREATED, function (e) {
                    var type = e.layerType
                        , layer = e.layer;
                    // layer.editing.enable();

                    drawnItems.addLayer(layer);
                    if (type == 'circle') {
                        var raduis = layer.getRadius()
                    } else {
                        var raduis = '';
                    }
                    var shape = layer.toGeoJSON()
                    var shape_for_db = JSON.stringify(shape);

                    var data = {
                        raduis: raduis,
                        type: type,
                        layer: shape_for_db,
                        depot_code: depot_code
                    };

                    WS.post('depots/updateLayers', data)
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur crée avec succé',
                                    'success'
                                );
                                // .then(function () {
                                //     $window.location.reload();
                                // });

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                    // layer.on("edit", function (event) {
                    //     var content = "";
                    //     if (type == "circle") {
                    //         content = 'Circle with : <ul><li> Radius : ' + layer.getRadius() + '</li>' +
                    //             '<li> Center : ' + layer.getLatLng() + ' </li></ul>';
                    //     }
                    //     else if (type === "polygon") {
                    //         content = ' Polygone with : ' + layer.getLatLngs()[0].length + ' nodes';
                    //     }
                    //     layer.bindPopup(content).openPopup();
                    // });
                });


                map.on(L.Draw.Event.EDITED, function (e) {
                    console.log('-------edited------------');
                    var layers = e.layers;
                    console.log(layers);
                    layers.eachLayer(function (layer) {
                        // Do whatever else you need to. (save to db; add to map etc)
                        // console.log('geometry: ', layer.toGeoJSON().geometry);
                        // console.log('layer.getLatLngs(): ', layer.getLatLngs());

                        //remove previous layer
                        // var area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]);
                        // console.log('area: ', area);
                        // map.addLayer(layer);

                        //issue: Finish drawing process with editable feature
                        var newLayer = L.GeoJSON.geometryToLayer(layer.toGeoJSON().geometry);
                        //console.log('newLayer', newLayer);
                        //newLayer.editing.enable();
                        map.addLayer(newLayer);

                        var layer_type = layer.toGeoJSON().geometry.type;
                        if (layer_type == 'circle') {
                            var raduis = layer.getRadius()
                        } else {
                            var raduis = '';
                        }
                        var shape = newLayer.toGeoJSON()
                        var shape_for_db = JSON.stringify(shape);
                        //console.log(layer_type, shape_for_db);
                        var data = {
                            raduis: raduis,
                            type: layer_type,
                            layer: shape_for_db,
                            depot_code: depot_code
                        };
                        //console.log(data);
                        WS.post('depots/updateLayers', data)
                            .then(function (response) {
                                if (response.data.success == true) {

                                    swal(
                                        'Succès',
                                        'Secteur crée avec succé',
                                        'success'
                                    );
                                    // .then(function () {
                                    //     $window.location.reload();
                                    // });
                                    $scope.$apply();

                                } else if (response.data.success == false) {
                                    swal('Error', 'Oups ', 'error');
                                }
                            })
                            .then(null, function (error) {
                                console.log(error);
                            });
                    });
                });

                //draw:deleted	

                map.on(L.Draw.Event.DELETED, function () {

                    WS.post('depots/updateLayers', { depot_code: depot_code })
                        .then(function (response) {
                            if (response.data.success == true) {
                                swal(
                                    'Succès',
                                    'Secteur supprimé avec succé',
                                    'success'
                                );
                                // .then(function () {
                                //     $window.location.reload();
                                // });;

                            } else if (response.data.success == false) {
                                swal('Error', 'Oups ', 'error');
                            }
                        })
                        .then(null, function (error) {
                            console.log(error);
                        });
                });

                $scope.$apply();
                setTimeout(function () {
                    map.invalidateSize();
                    console.log('initialized');
                }, 2000);
                //_.defer(map.invalidateSize.bind(map));
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    // $(document).ready(function () {
    //     console.log('$state.params.user_code', $state.params.user_code);
    //     if($state.params.user_code){
    //         $('#btn-detail-'+$state.params.user_code).click();
    //         console.log('clicked');
    //         $(".detail_user_modal").modal()
    //     }
    // });


    vm.getFournisseurs = function () {
        WS.get('fournisseurs')
            .then(function (response) {
                if (response.status == 200) {
                    vm.fournisseurs = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }


    vm.addNewMail = () => {
        WS.post('users/addNewMail', { email: vm.daily_mail })
            .then(function (response) {
                if (response.status == 200) {
                    vm.daily_mails.push({
                        email: response.data.email,
                        actif: response.data.actif,
                    });
                    vm.daily_mail = '';
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.daily_mails = [];
    vm.listOfDailyMails = function () {
        WS.getData('users/listOfDailyMails')
            .then(function (response) {
                if (response.status == 200) {
                    vm.daily_mails = response.data;
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }







    vm.deleteDailyMails = (email) => {
        WS.post('users/deleteDailyMails', { email })
            .then(function (response) {
                if (response.status == 200) {

                    vm.daily_mails = vm.daily_mails.filter(function (item) {
                        return item.email !== email
                    });
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };

    vm.listOfmailsVente = function () {
        WS.getData('users/listOfmailsVente')
            .then(function (response) {
                if (response.status == 200) {
                    vm.mails_cmd = response.data.commande;
                    vm.mails_bl = response.data.bl;
                    vm.mails_bl_gammes = response.data.bl_gammes;
                    vm.mails_commande_gammes = response.data.commande_gammes;
                    vm.mails_correction_bj = response.data.correction_bj;
                    vm.mails_correction_caisse = response.data.correction_caisse;

                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }
    vm.addNewMailVente = function (type) {
        var gammes = []; console.log(type)
        switch (type) {
            case 'bl':
                var email = vm.mail_bl;
                console.log(type, email)
                break;
            case 'commande':
                console.log(type, email)

                var email = vm.mail_cmd;
                break;
            case 'bl_gammes':
                console.log(type, email)
                var email = vm.mail_bl_gammes;
                gammes = vm.mail_bl_gammes_values
                break;

            case 'commande_gammes':
                console.log(type, email)
                var email = vm.mail_commande_gammes;
                gammes = vm.mail_commande_gammes_values
                break;
            case 'correction_bj' : 
                var email = vm.mail_correction_bj;
                console.log(type, email)

                break;
            case 'correction_caisse' : 
                console.log(type, email)
                var email = vm.mail_correction_caisse;
                break;
            default:
                //var email = null;
                console.log(type, email, 'default')
                break;
        }
        WS.post('users/addNewMailVente', { email, type, gammes })
            .then(function (response) {
                if (response.status == 200) {
                    switch (type) {
                        case 'bl':
                            vm.mails_bl = vm.mails_bl.filter(function (item) {
                                return item.email !== email
                            });
                            vm.mails_bl.push(response.data);
                            vm.mail_bl = '';
                            break;
                        case 'commande':
                            vm.mails_cmd = vm.mails_cmd.filter(function (item) {
                                return item.email !== email
                            });
                            vm.mails_cmd.push(response.data);
                            vm.mail_cmd = '';
                            break;
                        case 'bl_gammes':
                            vm.mails_bl_gammes = vm.mails_bl_gammes.filter(function (item) {
                                return item.email !== email
                            });

                            vm.mails_bl_gammes.push(response.data);
                            vm.mail_bl_gammes = '';
                            vm.mail_bl_gammes_values = '';
                            break;

                        case 'commande_gammes':
                            vm.mails_commande_gammes = vm.mails_commande_gammes.filter(function (item) {
                                return item.email !== email
                            });

                            vm.mails_commande_gammes.push(response.data);
                            vm.mail_commande_gammes = '';
                            vm.mail_commande_gammes_values = '';
                            break;

                        case 'correction_bj':  

                            vm.mails_correction_bj = vm.mails_correction_bj.filter(function (item) {
                                return item.email !== email
                            });
                            vm.mails_correction_bj.push(response.data);
                            vm.mail_correction_bj = '';
                            break;

                        case 'correction_caisse':  
                            vm.mails_correction_caisse = vm.mails_correction_caisse.filter(function (item) {
                                return item.email !== email
                            });
                            vm.mails_correction_caisse.push(response.data);
                            vm.mail_correction_caisse = '';
                            break;
                        default:
                            //var email = null;
                            break;
                    }
                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    }

    vm.deleteMailVente = (id, email, type) => {
        console.log(id, email, type)
        WS.post('users/deleteMailVente', { id })
            .then(function (response) {
                if (response.status == 200) {
                    if (type == "bl_gammes") {
                        vm.mails_bl_gammes = vm.mails_bl_gammes.filter(function (item) {
                            return item.email !== email
                        });
                    } else if (type == "commande_gammes") {
                        vm.mails_commande_gammes = vm.mails_commande_gammes.filter(function (item) {
                            return item.email !== email
                        });
                    } else {
                        if (type == 'commande') {
                            vm.mails_cmd = vm.mails_cmd.filter(function (item) {
                                return item.email !== email
                            });
                        } else if(type == 'bl') {
                            vm.mails_bl = vm.mails_bl.filter(function (item) {
                                return item.email !== email
                            });
                        } else if(type == 'correction_bj') {
                            vm.mails_correction_bj = vm.mails_correction_bj.filter(function (item) {
                                return item.email !== email
                            });
                        } else if(type == 'correction_caisse') {
                            vm.mails_correction_caisse = vm.mails_correction_caisse.filter(function (item) {
                                return item.email !== email
                            });
                        }
                    }


                    $scope.$apply();
                } else { }
            })
            .then(null, function (error) {
                console.log(error);
            });
    };
}
export default {
    name: 'userController',
    fn: userController
};